import { render, staticRenderFns } from "./MarketPolicyIndex.vue?vue&type=template&id=644b0d50&scoped=true&"
import script from "./MarketPolicyIndex.vue?vue&type=script&lang=js&"
export * from "./MarketPolicyIndex.vue?vue&type=script&lang=js&"
import style0 from "./MarketPolicyIndex.vue?vue&type=style&index=0&id=644b0d50&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "644b0d50",
  null
  
)

export default component.exports