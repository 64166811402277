<script>
import api from '@/services/api';
import PageTitle from '@/components/PageTitle.vue';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import { BButton, BCol, BPagination, BRow, BTable } from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns.vue';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import { checkLang, generateIconColorToBoolean, generateIconToBoolean } from '@/util/helper';
import vSelect from 'vue-select';

export default {
    name: 'MarketPolicyIndex',
    components: {
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        vSelect,
        BButton,
        BTable,
        BCol,
        BRow,
        BPagination
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            perPageList: [10, 20, 30, 50, 100],
            localLang: localStorage.getItem('lang'),
            requestPending: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = parseInt(query.page) || 1;
                this.pagination.perPage = parseInt(query.perPage) || 10;
                this.getPolicy();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: parseInt(newPage),
                    perPage: parseInt(this.query.perPage) || 10
                });
            }
        },

        'pagination.perPage': {
            handler(perPage) {
                this.replaceRouter({
                    ...this.query,
                    page: 1,
                    perPage: parseInt(perPage)
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'title',
                    label: this.$t('titles.title'),
                    formatter: (name) => checkLang(name)
                },
                {
                    key: 'type',
                    label: this.$t('titles.type')
                },
                // {
                //     key: `content_${this.localLang}`,
                //     label: this.$t('titles.description')
                // },
                {
                    key: 'merchant',
                    label: this.$t('titles.merchant'),
                    formatter: (merchant) => merchant?.name || ''
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    methods: {
        async getPolicy() {
            const body = {
                page: this.pagination.current,
                limit: this.pagination.perPage
            };
            await api.policy
                .fetchAllMarketPolicies(body)
                .then((response) => {
                    this.items = response.data.result;
                    this.items = this.items.filter((item) => item.type === 'market_confidential');

                    this.pagination = response.data.pagination;
                    if (this.pagination.current > this.pagination.totalItem) {
                        this.pagination.current = 1;
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async deletePolicy(id) {
            const params = {
                id: id
            };
            await api.policy
                .deleteMarketPolicy(params)
                .then(() => {
                    this.getPolicy();
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        replaceRouter(query) {
            this.$router.push({ query }).catch(() => {});
        },

        checkLocales(value) {
            if (typeof value === 'object') {
                if (this.localLang === 'uz') {
                    return value.uz;
                } else if (this.localLang === 'ru') {
                    return value.ru;
                } else {
                    return value.en;
                }
            }
            return value;
        },

        generateIconToBoolean,
        generateIconColorToBoolean
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title>{{ $t('titles.privacy') }}</template>
            <template v-if="hasAccess('market_privacy', 'create')" #button>
                <b-button
                    v-b-modal.createUpdateModal
                    class="ml-auto"
                    variant="success"
                    @click="$router.push({ name: 'market-policy-show' })"
                >
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <div class="d-flex align-items-center justify-content-end mb-2">
            <div class="d-flex align-items-center">
                Per page:
                <v-select
                    v-model="pagination.perPage"
                    :options="perPageList"
                    class="ml-2"
                    style="flex-grow: 1; background-color: #fff; height: 37px; width: 180px"
                />
            </div>
        </div>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(id)="{ item, index }">
                    {{ index + 1 }}
                </template>

                <template #cell(images)="{ item }">
                    <div class="table-img">
                        <img
                            v-if="item.images && item.images.length > 0"
                            :src="item.images[0].path"
                        />
                        <img v-else src="@/assets/images/icons/no-photo.png" />
                    </div>
                </template>

<!--                <template #cell(type)="{ item }">-->
<!--                    {{ $t(`titles.${item.type}`) }}-->
<!--                </template>-->

                <template #cell(description)="{ item }">
                    <span v-if="item.description" v-html="checkLocales(item.description)"></span>
                    <span v-else></span>
                </template>

                <template #cell(duration_type)="{ item, index }">
                    {{ items[index].duration }}
                    {{ $t(`titles.${item.duration_type}`) }}
                </template>

                <template #cell(is_published)="{ item }">
                    <feather-icon
                        :class="['stroke-current', generateIconColorToBoolean(item.is_published)]"
                        :icon="generateIconToBoolean(item.is_published)"
                    />
                </template>

                <template #cell(actions)="{ item, index }">
                    <table-action-btns
                        :delete-access="{ section: 'market_policy', permission: 'delete' }"
                        :delete-handler="deletePolicy"
                        :index="index"
                        :item="item"
                        :update-access="{ section: 'market_policy', permission: 'update' }"
                        @openModal="
                            $router.push({ name: 'market-policy-show', params: { id: item.id } })
                        "
                    />
                </template>
            </b-table>
        </div>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3 mb-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.table-img {
    width: 60px;
    height: 60px;
    background-color: #999999;
    border-radius: 0.25rem;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.25rem;
    }
}

::v-deep .vs__dropdown-menu {
    max-height: 250px !important;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}
</style>
